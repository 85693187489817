import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LinkSplashComponent} from './link-splash/link-splash.component';
import {LinkFrameComponent} from './link-frame/link-frame.component';
import {LinkPreviewHostComponent} from './link-preview-host/link-preview-host.component';
import {UiModule} from '@common/core/ui/ui.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared.module';
import {LinkCustomPageComponent} from './link-custom-page/link-custom-page.component';
import {PagesModule} from '@common/core/pages/shared/pages.module';
import {RequestPasswordPanelModule} from '@common/shared/request-password-panel/request-password-panel.module';

@NgModule({
    declarations: [
        LinkPreviewHostComponent,
        LinkFrameComponent,
        LinkSplashComponent,
        LinkCustomPageComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        UiModule,
        SharedModule,
        PagesModule,
        RequestPasswordPanelModule,
    ],
    entryComponents: [
        LinkFrameComponent,
        LinkSplashComponent,
        LinkCustomPageComponent,
    ]
})
export class LinkPreviewModule {
}
