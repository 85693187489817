/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/ui/material-navbar/material-navbar.component.ngfactory";
import * as i3 from "../../../../common/core/ui/material-navbar/material-navbar.component";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../common/auth/current-user";
import * as i6 from "../../../../common/core/ui/breakpoints.service";
import * as i7 from "../../share-link-btns/share-link-btns.component.ngfactory";
import * as i8 from "../../share-link-btns/share-link-btns.component";
import * as i9 from "../../../../common/core/ui/toast.service";
import * as i10 from "../../../../common/core/translations/translations.service";
import * as i11 from "../../link-overlay/link-overlay.component.ngfactory";
import * as i12 from "../../link-overlay/link-overlay.component";
import * as i13 from "@angular/common";
import * as i14 from "../../../../common/core/ui/ad-host/ad-host.component.ngfactory";
import * as i15 from "../../../../common/core/ui/ad-host/ad-host.component";
import * as i16 from "../../../../common/core/utils/lazy-loader.service";
import * as i17 from "./link-frame.component";
import * as i18 from "@angular/platform-browser";
var styles_LinkFrameComponent = [i0.styles];
var RenderType_LinkFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkFrameComponent, data: {} });
export { RenderType_LinkFrameComponent as RenderType_LinkFrameComponent };
function View_LinkFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "material-navbar", [], [[2, "transparent", null]], null, null, i2.View_MaterialNavbar_0, i2.RenderType_MaterialNavbar)), i1.ɵdid(1, 114688, null, 0, i3.MaterialNavbar, [i4.Settings, i5.CurrentUser, i1.ElementRef, i6.BreakpointsService], { showToggleButton: [0, "showToggleButton"], hideRightSideActions: [1, "hideRightSideActions"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "share-link-btns", [["class", "push-right"]], [[2, "colored", null]], null, null, i7.View_ShareLinkBtnsComponent_0, i7.RenderType_ShareLinkBtnsComponent)), i1.ɵdid(3, 49152, null, 0, i8.ShareLinkBtnsComponent, [i9.Toast, i10.Translations], { link: [0, "link"], showCopyBtn: [1, "showCopyBtn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = true; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _co.link; var currVal_5 = true; _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).transparent; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 3).colored; _ck(_v, 2, 0, currVal_3); }); }
function View_LinkFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-overlay", [], null, null, null, i11.View_LinkOverlayComponent_0, i11.RenderType_LinkOverlayComponent)), i1.ɵdid(1, 573440, null, 0, i12.LinkOverlayComponent, [i1.ElementRef], { overlay: [0, "overlay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link.custom_page; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LinkFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkFrameComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ad-host", [["slot", "ads.frame.top"]], [[8, "id", 0]], null, null, i14.View_AdHostComponent_0, i14.RenderType_AdHostComponent)), i1.ɵdid(3, 245760, null, 0, i15.AdHostComponent, [i1.ElementRef, i1.Renderer2, i4.Settings, i5.CurrentUser, i16.LazyLoaderService], { slot: [0, "slot"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkFrameComponent_2)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "iframe", [], [[8, "src", 5], [2, "full-height", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.link.type === "frame"); _ck(_v, 1, 0, currVal_0); var currVal_2 = "ads.frame.top"; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.link.type === "overlay"); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 3).randomId; _ck(_v, 2, 0, currVal_1); var currVal_4 = _co.safeUrl; var currVal_5 = (_co.link.type === "overlay"); _ck(_v, 6, 0, currVal_4, currVal_5); }); }
export function View_LinkFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-frame", [], null, null, null, View_LinkFrameComponent_0, RenderType_LinkFrameComponent)), i1.ɵdid(1, 49152, null, 0, i17.LinkFrameComponent, [i18.DomSanitizer], null, null)], null, null); }
var LinkFrameComponentNgFactory = i1.ɵccf("link-frame", i17.LinkFrameComponent, View_LinkFrameComponent_Host_0, { link: "link" }, {}, []);
export { LinkFrameComponentNgFactory as LinkFrameComponentNgFactory };
