import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Link} from '../../link/link';
import {timer} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'link-custom-page',
    templateUrl: './link-custom-page.component.html',
    styleUrls: ['./link-custom-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkCustomPageComponent {
    @Input() link: Link;

    // redirect user after 10 seconds
    public timeout$ = timer(0, 1000).pipe(
        map(i => 10 - i),
        takeWhile(n => n > 0)
    );

    public redirect() {
        window.location.href = this.link.long_url;
    }
}
