import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareLinkBtnsComponent} from './share-link-btns/share-link-btns.component';
import {UiModule} from '@common/core/ui/ui.module';
import {CopyLinkBtnComponent} from './copy-link-btn/copy-link-btn.component';
import { FooterComponent } from './footer/footer.component';
import { LinkOverlayComponent } from './link-overlay/link-overlay.component';

@NgModule({
    declarations: [
        ShareLinkBtnsComponent,
        CopyLinkBtnComponent,
        FooterComponent,
        LinkOverlayComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
    ],
    exports: [
        ShareLinkBtnsComponent,
        CopyLinkBtnComponent,
        FooterComponent,
        LinkOverlayComponent,
    ]
})
export class SharedModule {
}
