import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
const BASE_URI = 'link';
export class LinkService {
    constructor(http) {
        this.http = http;
    }
    index(params = {}) {
        return this.http.get(`${BASE_URI}`, params);
    }
    get(id, params = {}) {
        return this.http.get(`${BASE_URI}/${id}`, params);
    }
    create(params) {
        return this.http.post(BASE_URI, params);
    }
    update(id, params) {
        return this.http.put(`${BASE_URI}/${id}`, params);
    }
    delete(ids) {
        return this.http.delete(`${BASE_URI}/${ids}`);
    }
    getCurrentUserReports(params = {}) {
        return this.http.get(`${BASE_URI}/reports`, params);
    }
}
LinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkService_Factory() { return new LinkService(i0.ɵɵinject(i1.AppHttpClient)); }, token: LinkService, providedIn: "root" });
