import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LandingComponent} from './landing/landing.component';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@common/core/core.module';
import {AuthModule} from '@common/auth/auth.module';
import {AccountSettingsModule} from '@common/account-settings/account-settings.module';
import {PagesModule} from '@common/core/pages/shared/pages.module';
import {NewLinkFormComponent} from './landing/new-link-form/new-link-form.component';
import {APP_CONFIG} from '@common/core/config/app-config';
import {LINK_CONFIG} from '../link-config';
import {LinkPreviewModule} from './shared/link-preview/link-preview.module';

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        NewLinkFormComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([], {scrollPositionRestoration: 'top'}),
        CoreModule.forRoot(),
        AuthModule,
        AccountSettingsModule,
        PagesModule,
        AppRoutingModule,
        LinkPreviewModule,
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: LINK_CONFIG,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
