/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-preview-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/shared/request-password-panel/request-password-panel.component.ngfactory";
import * as i3 from "../../../../common/shared/request-password-panel/request-password-panel.component";
import * as i4 from "../../../../common/core/http/app-http-client.service";
import * as i5 from "../link-frame/link-frame.component.ngfactory";
import * as i6 from "../link-frame/link-frame.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../link-splash/link-splash.component.ngfactory";
import * as i9 from "../link-splash/link-splash.component";
import * as i10 from "../../../../common/core/config/settings.service";
import * as i11 from "../link-custom-page/link-custom-page.component.ngfactory";
import * as i12 from "../link-custom-page/link-custom-page.component";
import * as i13 from "../../../../common/core/pages/shared/not-found-page/not-found-page.component.ngfactory";
import * as i14 from "../../../../common/core/pages/shared/not-found-page/not-found-page.component";
import * as i15 from "@angular/common";
import * as i16 from "./link-preview-host.component";
import * as i17 from "../../../../common/core/bootstrapper.service";
import * as i18 from "../../../../common/core/meta/meta-tags.service";
var styles_LinkPreviewHostComponent = [i0.styles];
var RenderType_LinkPreviewHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkPreviewHostComponent, data: {} });
export { RenderType_LinkPreviewHostComponent as RenderType_LinkPreviewHostComponent };
function View_LinkPreviewHostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "request-password-panel", [], null, [[null, "passwordValid"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("passwordValid" === en)) {
        var pd_0 = (_co.hidePasswordPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RequestPasswordPanelComponent_0, i2.RenderType_RequestPasswordPanelComponent)), i1.ɵdid(1, 49152, null, 0, i3.RequestPasswordPanelComponent, [i4.AppHttpClient], { config: [0, "config"] }, { passwordValid: "passwordValid" }), i1.ɵpod(2, { table: 0, id: 1 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "links", _v.parent.context.ngIf.id); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkPreviewHostComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-frame", [], null, null, null, i5.View_LinkFrameComponent_0, i5.RenderType_LinkFrameComponent)), i1.ɵdid(1, 49152, null, 0, i6.LinkFrameComponent, [i7.DomSanitizer], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkPreviewHostComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-frame", [], null, null, null, i5.View_LinkFrameComponent_0, i5.RenderType_LinkFrameComponent)), i1.ɵdid(1, 49152, null, 0, i6.LinkFrameComponent, [i7.DomSanitizer], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkPreviewHostComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-splash", [], null, null, null, i8.View_LinkSplashComponent_0, i8.RenderType_LinkSplashComponent)), i1.ɵdid(1, 49152, null, 0, i9.LinkSplashComponent, [i10.Settings], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkPreviewHostComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-custom-page", [], null, null, null, i11.View_LinkCustomPageComponent_0, i11.RenderType_LinkCustomPageComponent)), i1.ɵdid(1, 49152, null, 0, i12.LinkCustomPageComponent, [], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkPreviewHostComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found-page", [], null, null, null, i13.View_NotFoundPageComponent_0, i13.RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i14.NotFoundPageComponent, [i10.Settings], null, null)], null, null); }
function View_LinkPreviewHostComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i15.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewHostComponent_4)), i1.ɵdid(3, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewHostComponent_5)), i1.ɵdid(5, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewHostComponent_6)), i1.ɵdid(7, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewHostComponent_7)), i1.ɵdid(9, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewHostComponent_8)), i1.ɵdid(11, 16384, null, 0, i15.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "frame"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "overlay"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "splash"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "page"; _ck(_v, 9, 0, currVal_4); }, null); }
function View_LinkPreviewHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LinkPreviewHostComponent_2)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i15.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["linkPreview", 2]], null, 0, null, View_LinkPreviewHostComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.ngIf == null) ? null : _v.context.ngIf.has_password) && !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.passwordConfirmed$))); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_LinkPreviewHostComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found-page", [], null, null, null, i13.View_NotFoundPageComponent_0, i13.RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i14.NotFoundPageComponent, [i10.Settings], null, null)], null, null); }
export function View_LinkPreviewHostComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_LinkPreviewHostComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i15.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["noLink", 2]], null, 0, null, View_LinkPreviewHostComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.link$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LinkPreviewHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "link-preview-host", [], null, null, null, View_LinkPreviewHostComponent_0, RenderType_LinkPreviewHostComponent)), i1.ɵdid(1, 114688, null, 0, i16.LinkPreviewHostComponent, [i17.Bootstrapper, i18.MetaTagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkPreviewHostComponentNgFactory = i1.ɵccf("link-preview-host", i16.LinkPreviewHostComponent, View_LinkPreviewHostComponent_Host_0, {}, {}, []);
export { LinkPreviewHostComponentNgFactory as LinkPreviewHostComponentNgFactory };
