/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-link-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/core/translations/translate.pipe";
import * as i3 from "../../../common/core/translations/translations.service";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "./copy-link-btn.component";
import * as i17 from "../../../common/core/ui/toast.service";
var styles_CopyLinkBtnComponent = [i0.styles];
var RenderType_CopyLinkBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyLinkBtnComponent, data: {} });
export { RenderType_CopyLinkBtnComponent as RenderType_CopyLinkBtnComponent };
export function View_CopyLinkBtnComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.TransPipe, [i3.Translations]), (_l()(), i1.ɵeld(1, 16777216, null, null, 5, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.copyLink() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 212992, null, 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i11.Platform, i6.AriaDescriber, i6.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "copy-link"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "Copy link")); _ck(_v, 3, 0, currVal_2); var currVal_5 = "copy-link"; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).inline; var currVal_4 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_CopyLinkBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "copy-link-btn", [], null, null, null, View_CopyLinkBtnComponent_0, RenderType_CopyLinkBtnComponent)), i1.ɵdid(1, 49152, null, 0, i16.CopyLinkBtnComponent, [i17.Toast], null, null)], null, null); }
var CopyLinkBtnComponentNgFactory = i1.ɵccf("copy-link-btn", i16.CopyLinkBtnComponent, View_CopyLinkBtnComponent_Host_0, { link: "link" }, {}, []);
export { CopyLinkBtnComponentNgFactory as CopyLinkBtnComponentNgFactory };
