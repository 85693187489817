/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/core/translations/translate.pipe";
import * as i3 from "../../../common/core/translations/translations.service";
import * as i4 from "../../../common/core/ui/custom-menu/custom-menu.component.ngfactory";
import * as i5 from "../../../common/core/ui/custom-menu/custom-menu.component";
import * as i6 from "../../../common/core/config/settings.service";
import * as i7 from "../../../common/auth/current-user";
import * as i8 from "../../../common/shared/appearance/appearance-listener.service";
import * as i9 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.TransPipe, [i3.Translations]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "custom-menu", [["position", "footer"]], [[2, "hidden", null], [2, "vertical", null], [2, "horizontal", null]], null, null, i4.View_CustomMenuComponent_0, i4.RenderType_CustomMenuComponent)), i1.ɵdid(2, 245760, null, 0, i5.CustomMenuComponent, [i6.Settings, i7.CurrentUser, i1.ChangeDetectorRef, i8.AppearanceListenerService, i1.ChangeDetectorRef], { horizontal: [0, "horizontal"], position: [1, "position"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "social"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " \u00A9 ", " ", "\n"])), i1.ɵppd(6, 1)], function (_ck, _v) { var currVal_3 = true; var currVal_4 = "footer"; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).shouldHide; var currVal_1 = i1.ɵnov(_v, 2).vertical; var currVal_2 = i1.ɵnov(_v, 2).horizontal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "Copyright")); var currVal_6 = _co.settings.get("branding.site_name"); var currVal_7 = _co.year(); _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i9.FooterComponent, [i6.Settings], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("footer", i9.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
