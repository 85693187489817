import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import * as copy from 'copy-to-clipboard';
import {Toast} from '@common/core/ui/toast.service';
import {Link} from '../link/link';
import {Messages} from '../../messages.enum';

@Component({
    selector: 'copy-link-btn',
    templateUrl: './copy-link-btn.component.html',
    styleUrls: ['./copy-link-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyLinkBtnComponent {
    @Input() link: Link;

    constructor(private toast: Toast) {}

    public copyLink() {
        const status = copy(this.link.short_url);
        if (status) {
            this.toast.open(Messages.LINK_COPY_SUCCESS);
        }
    }
}
